import React from "react";
import BreadCrum from "../../components/BreadCrum";
import { ContentLayout } from "../../Layout";
import PageHeader from "../../components/PageHeader";
import ImageViewList from "../../components/ImageViewList";

const Clients = () => {
  const clientsImages = [
    {
      title: "Processminer ",
      img: "images/client-processminer.png",
      url: "https://processminer.com/",
    },
    {
      title: "nerds-on-wheels ",
      img: "images/client-nerds-on-wheels.png",
      url: "https://www.nerds-on-wheels.com/",
    },
    {
      title: "TPC ",
      img: "images/client-tpc.png",
      url: "https://www.pamperedchef.com/",
    },
    {
      title: "bavasoft",
      img: "images/client-bavasoft.gif",
      url: "https://www.bavasoft.com/",
    },
    {
      title: "LTIMindtree",
      img: "images/client-ltimindtree.png",
      url: "https://www.ltimindtree.com/",
    },
    {
      title: "Abbott",
      img: "images/client-abbott.jpg",
      url: "https://www.abbott.com/",
    },
    {
      title: "einfochips",
      img: "images/client-eic-arrow.png",
      url: "https://www.einfochips.com/",
    },
    {
      title: "buzzworks",
      img: "images/client-buzzworks.png",
      url: "https://buzzworks.com/",
    }
  ];

  return (
    <>
      <ContentLayout>
        <PageHeader name="Clients" />
        <div className="entry-content">
          <p>
            We consider our clients as our partners. We believe in word of mouth
            marketing and customer satisfaction is the key to growing our
            business. We want to see our clients happy and succeed with us.
          </p>

          <p>
            We intend to provide a transparent, honest, professional and
            creative suite of services to our clients. Our work ethics,
            professionalism and timely delivery of services have helped us
            retain our clients and we intend to continue that with more rigour.
            We strive for constructive
            feedback from our clients and constantly improve our services and
            processes.
          </p>

          <p>
            We have been working with our clients for a very long time and we’re
            hoping to earn many more long lasting relationships in future. Just
            earning someone’s trust is not enough, but keeping it matters more
            for us.
          </p>
        </div>
        <ImageViewList ImageData={clientsImages} />
      </ContentLayout>
    </>
  );
};

export default Clients;
