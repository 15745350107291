import React from "react";
import { Link, useLocation } from "react-router-dom";

const BreadCrum = () => {
  const location = useLocation();
  const locationName = location.pathname.replace("/", "");
  const pathName =  locationName[0].toUpperCase() + locationName.slice(1).toLowerCase();

  return (
    <div id="breadcrumb">
      <div className="container">
        <div role="navigation" className="breadcrumb-trail breadcrumbs">
          <ul className="trail-items">
            <li className="trail-item trail-begin">
              <Link to="/">
                <span>Home</span>
              </Link>
            </li>
            <li className="trail-item trail-end">
              <span>{pathName}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BreadCrum;
