import React, { useState, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoImage from "../../images/logo.jpg";
import HeaderNav from "./HeaderNav";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className="sticky-wrapper" id="sticky-wrapper">
      <div
        id="masthead-sticky-wrapper"
        className="sticky-wrapper is-sticky"
        style={{ height: 110 }}
      >
        <header
          id="masthead"
          className="site-header main-navigation-holder mean-container"
          role="banner"
        >
          <div className="mean-bar">
            <Drawer
              className=" mean-container mobileMenu"
              anchor="top"
              open={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
            >
              <div className="mean-nav" onClick={() => setIsMenuOpen(false)}>
                <HeaderNav />
              </div>
            </Drawer>
          </div>
          <div className="container">
            <div className="head-wrap">
              <div className="site-branding">
                <Link to="/" className="custom-logo-link">
                  <img
                    src={LogoImage}
                    className="custom-logo"
                    alt="MVJSoft"
                    width="236"
                    height="50"
                  />
                </Link>
              </div>

              <div className="mobile-menu-btn">
                <Button
                  onClick={handleToggleMenu}
                  className="meanmenu-reveal"
                  size="large"
                >
                  {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                </Button>
              </div>

              <div id="main-nav" className="clear-fix">
                <HeaderNav />
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
