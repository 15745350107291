import React from "react";

const Hero = ({ image }) => {
  return (
    <div className="hero-section overlay-enabled">
      <div className="cycle-slide">
        <img src={image} alt="Hero " />
      </div>
    </div>
  );
};

export default Hero;
