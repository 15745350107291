import React from "react";
import { NavLink } from "react-router-dom";

const HeaderNav = () => {
  return (
    <nav id="site-navigation" className="main-navigation" role="navigation">
      <div className="wrap-menu-content">
        <div className="menu-main-navigation-container">
          <ul id="primary-menu" className="menu">
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home  page_item page-item-6 current_page_item menu-item-20">
              <NavLink to="/" aria-current="page">
                Home
              </NavLink>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19">
              <NavLink to="technologies">Technologies</NavLink>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18">
              <NavLink to="clients">Clients</NavLink>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17">
              <NavLink to="team">Team</NavLink>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-203">
              <NavLink to="career">Career</NavLink>
            </li>
            {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-203">
              <a target='_blank' href="https://mvjsoft.wordpress.com/">Blog</a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default HeaderNav;
