import React from "react";
import BreadCrum from "../../components/BreadCrum";
import PageHeader from "../../components/PageHeader";
import { ContentLayout } from "../../Layout";

import ImageViewList from "../../components/ImageViewList";

const Technologies = () => {
  const ImageData = [
    {
      title: "Java",
      img: "./images/tech-java.png",
    },
    {
      title: "Spring Framework",
      img: "./images/tech-spring.png",
    },
    {
      title: "RDBMS",
      img: "./images/tech-rdbms.png",
    },
    {
      title: "Mongo DB",
      img: "./images/tech-mongodb.png",
    },
    {
      title: "AI ML",
      img: "./images/tech-ai-ml.png",
    },
    {
      title: "Python",
      img: "./images/tech-python.jpeg",
    },
    {
      title: "Application Performance Management",
      img: "./images/tech-apm.png",
    },
    {
      title: "Automation Testing",
      img: "./images/tech-automation-testing.png",
    },
    {
      title: "DevOps",
      img: "./images/tech-devops.png",
    },
    {
      title: "Linux",
      img: "./images/tech-linux.jpeg",
    },
    {
      title: "Amazon Web Service",
      img: "./images/tech-aws.gif",
    },
    {
      title: "Azure",
      img: "./images/tech-azure.jpeg",
    },
    {
      title: "Docker",
      img: "./images/tech-docker.png",
    },
    {
      title: "Kubernetes",
      img: "./images/tech-kubernetes.png",
    },
    {
      title: "HTML-CSS",
      img: "./images/tech-html-css.png",
    },
    {
      title: "React JS",
      img: "./images/tech-reactjs.png",
    },
    {
      title: "Node JS",
      img: "./images/tech-nodejs.jpeg",
    },
    {
      title: "Atlassian",
      img: "./images/tech-atlassian.png",
    },
    {
      title: "Hybris",
      img: "./images/tech-hybris.jpg",
    },
    {
      title: "Salesforce",
      img: "./images/tech-salesforce.png",
    }
  ];

  return (
    <>
      <ContentLayout>
        <PageHeader name="Technologies" />
        <div className="entry-content">
          <p>
            Building modern software keeping security and performance in mind is
            very complex.
          </p>

          <p>
            Nowadays there are abundant competing technologies available. But
            choosing the right technology, for the task at hand for a given
            amount of resources, is of paramount importance. We have experience
            working in many different technologies in all tiers of application
            development and we can provide our customers insight into available
            options and help them choose the right technology for their needs.
          </p>

          <p>
            From conceiving, specifying, designing, programming, documenting,
            testing to maintenance,&nbsp; we have experience in all various
            phases of software development. We are involved in creating and
            maintaining applications, frameworks and other software components.
            We strictly follow agile processes and believe in frequent
            communication and attention to details.
          </p>

          <p>
            Our services and products are customized and are aimed to serve our
            customers’ needs in the most efficient manner within a given budget
            and resources. We provide our customers with resilient systems that 
            make their life very convenient.
          </p>

          <p>
            We strive to use cutting edge technologies. Below are some of the
            prominent technologies we are experienced in and the list keeps
            growing:
          </p>
        </div>
        <ImageViewList ImageData={ImageData} />
      </ContentLayout>
    </>
  );
};

export default Technologies;
