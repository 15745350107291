import React from "react";

const JobListing = () => {
  const jobListData = [
    {
      title: "Intern (Java)",
      url: "https://docs.google.com/document/d/1IczO2sk-yOkTjBlFTkDDVzdgr3rJxj7JSHlRih_sBjM/edit?usp=sharing"
    },
    {
      title: "Intern (.Net)",
      url: "https://docs.google.com/document/d/1UtH4gzj8jbHQCwWdEj7Eqe-1DeIbM2QPymLaAP45J50/edit?usp=sharing"
    },
    {
      title: "Intern (Data Science)",
      url: "https://docs.google.com/document/d/16CfzVkjOKHyC1p-R-REIocY358WPkHTJ93XuqpjhRFw/edit?usp=sharing"
    },
    {
      title: "Intern (Automation, DevOps, Salesforce)",
      url: "https://docs.google.com/document/d/1ShMsweY73cFEdA_a5ihlaO9TpeAQukgl_MYhtiKjII8/edit?usp=sharing"
    },
    {
      title: "Junior-Mid Level Java Developer",
      url: "https://docs.google.com/document/d/13NW5mLJWTHo7U6gJ4s-6AwvEpz_7LdjeH8b-dQPv9SU/edit?usp=sharing",
    },
    {
      title: "Junior-Mid Level Data Scientist",
      url: "https://docs.google.com/document/d/1MTj4G_HXthGVpU_BE6JSpAwnq8o2nSsRklURM15uHzs/edit?usp=sharing"
    },
    {
      title: "Senior Data Scientist",
      url: "https://docs.google.com/document/d/1-dThLTr_uDUMpDV7MDuWQxByr50TVhu4TCXe65BIRVQ/edit?usp=sharing"
    }
  ];
  return (
    <div className="job-list">
      {jobListData &&
        jobListData.map((item, i) => {
          return (
            <div key={`job-${i}`} className="job-card">
              <a target="_blank" href={item.url}>
                <div className="CardContent">
                  <div className="position">
                    <h3>{item.title}</h3>
                  </div>
                  <ul className="meta">
                    <li className="job-type full-time">{item.jobType}</li>
                  </ul>
                </div>
              </a>
            </div>
          );
        })}
    </div>
  );
};

export default JobListing;
