import React from 'react';
const ourServices = ()=> {
    return (
        <>
             <section  className=" our-service-section widget business_point_widget_services py-60">
                <div className="container">
                <div className="services-list bp-services">
                    <div className="section-title">
                        <h3 className="widget-title">Our Services</h3>
                        <div className="seperator">
                            <span><i className="fa fa-laptop"></i></span>
                        </div>
                        <p>We are a full service software company</p>
                    </div>
                    <div className="inner-wrapper">

                        <div className="services-item">
                            <div className="service-icon">
                                <i className="fa fa-cog"></i>
                            </div>
                            <h4 className="services-item-title">Development</h4>
                            <p>We deliver high quality and thoroughly tested code with comprehensive documentation</p>

                        </div>
                        <div className="services-item">
                            <div className="service-icon">
                                <i className="fa fa-cog"></i>
                            </div>
                            <h4 className="services-item-title">Maintenance</h4>
                            <p>We provide on-going maintenance of the software and infrastructure including any bug fixes and enhancements to ensure 99.9999% uptime</p>

                        </div>
                        <div className="services-item">
                            <div className="service-icon">
                                <i className="fa fa-cog"></i>
                            </div>
                            <h4 className="services-item-title">Support</h4>
                            <p>We have experience handling 24x7 level-1 support with max 15 minutes SLA, as well as level-2 and level-3 support</p>

                        </div>
                    </div>
                </div>
            </div></section>
        </>
    )
}
export default ourServices;