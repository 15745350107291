import React from "react";
import BreadCrum from "../../components/BreadCrum";
import PageHeader from "../../components/PageHeader";
import { ContentLayout } from "../../Layout";
import JobListing from "./JobListing";

const Career = ()=> {


    return(
        <>
            <ContentLayout>
                <PageHeader name='Current Openings' />
                <JobListing  />
            </ContentLayout>
        </>
    )
}


export default Career