import React from "react";
import BreadCrum from "../../components/BreadCrum";
import PageHeader from "../../components/PageHeader";
import { ContentLayout } from "../../Layout";

const Team = ()=> {
    return(
        <>
           <ContentLayout>
                <PageHeader name='Team' />
                <div class="entry-content">
				
<p>Ours is a highly experienced team. We work very closely with our clients. We pay attention to every detail, evaluate them, design appropriate solutions and ensure rapid and optimum delivery.</p>



<p>We have built a great culture where everyone in the team helps and trusts one another. This has built a lot of confidence in what we as a team can deliver. We really value our team’s time and commitment and always strive to make them happier.</p>



<p><strong>Mita</strong>&nbsp;is our Co-Founder and CEO. She has excellent people skills. She has worked very closely with a lot of clients and vendors in her career.</p>



<p><strong><a rel="noreferrer noopener" href="https://www.linkedin.com/in/mananjani/" target="_blank">Manan</a></strong>&nbsp;is our Technical Co-Founder and CTO. He has vast experience working on large distributed systems in various domains like construction softwares, document management systems, cms, e-commerce, fintech and more recently AI and ML. He has worked on a lot of different technologies throughout his career.</p>



<p>We want our team to grow with us on a personal and professional level. We have tried to nurture an environment where everyone in the team has power to make decisions and everyone’s opinions matter.</p>
			</div>
           </ContentLayout>
        </>
    )
}


export default Team