import React from "react";

const PageHeader = ({name}) => {
  return (
    <header className="entry-header">
      <h2 className="entry-title">{name}</h2>{" "}
    </header>
  );
};

export default PageHeader;
