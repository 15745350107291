import React from "react";
import BreadCrum from "../components/BreadCrum";

const ContentLayout = (props) => {
  return (
    <>
      <BreadCrum />
      <div id="content" className="site-content">
        <div className="container">
          <div className="inner-wrapper">
            <div id="primary" className="content-area">
              <div className="page type-page status-publish hentry">
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentLayout;
