import React from "react";

const Footer = () => {
  return (
    <>
      <aside id="footer-widgets" className="widget-area">
        <div className="container">
          <div className="inner-wrapper">
            <div className="widget-column footer-active-3">
              <section id="text-2" className="widget widget_text">
                <h4 className="widget-title">About Us</h4>
                <div className="textwidget">
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      We are a full service software company. Our focus is to
                      deliver on-time quality work to our clients. We follow
                      Scrum and Kanban methods to deliver high quality software
                      in cutting-edge technologies. We are truly passionate
                      about technology and strongly believe in long term
                      benefits of applying Software Engineering principles
                      correctly.
                    </span>
                  </p>
                </div>
              </section>
            </div>
            <div className="widget-column footer-active-3">
              <section id="text-3" className="widget widget_text">
                <h4 className="widget-title">Contact Us</h4>
                <div className="textwidget">
                  <p>We love to hear from you. Please email us or call us.</p>
                  <p>
                    B-1114, K P Epitome
                    <br />
                    Nr. DAV School, Nr. Siddhi Vinayak Tower
                    <br />
                    Off S. G. Highway, Makarba
                    <br />
                    Ahmedabad – 380051, Gujarat, India
                  </p>
                  <p>
                    <i className="fa fa-phone-square faa-wrench animated"></i>
                    &nbsp;+91 910 616 2917
                  </p>
                  <p>
                    <i className="fa fa-envelope faa-horizontal animated"></i>
                    &nbsp;<a href="mailto:contact@mvjsoft.com">contact@mvjsoft.com</a>
                  </p>
                </div>
              </section>
            </div>

            <div className="widget-column footer-active-3">
              <section id="text-4" className="widget widget_text">
                <h4 className="widget-title">Find Us on Google</h4>
                <div className="textwidget">
                  <p>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d866.6564158429924!2d72.49952444573702!3d22.995792533649453!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcc7a3fbe2e6891ee!2sK%20P%20Epitome!5e0!3m2!1sen!2sin!4v1655719101201!5m2!1sen!2sin"
                      style={{border:0}}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </aside>
      <footer id="colophon" className="site-footer" role="contentinfo">
        <div className="container">
          <div className="copyright">
            <p>© 2014 - 2023 MVJ SOFT PVT. LTD. All rights reserved.</p>
            <p>Registered as a Pvt. Ltd. company since July 2022. 
              Company Registration Number: U72900GJ2022PTC133467</p>
            <p>Was previously registered as a proprietor firm since 
              August 2014 (Reg: PRC010781000218)</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
