import React from "react";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const ImageViewList = ({ ImageData }) => {
  return (
    <ul className="imageList">
      {ImageData.map((item,i) => (
        <li key={`imageItem-${i}`}>
          <a target="_blank" href={item.url}>
            <img
              src={`${item.img}`}
              srcSet={`${item.img}`}
              alt={item.title}
              loading="lazy"
            />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default ImageViewList;
