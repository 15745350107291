import React from "react";
import Hero from "../../components/Hero";
import HeroImage from "../../images/hero.jpg";
import OurServices from "./OurService";

const HomePage = () => {
  return (
    <>
      <Hero image={HeroImage} />
    <div id="home-page-widget-area">
      <section className="about-section business_point_widget_call_to_action widget">
        <div className="container">
          <div className="cta-widget">
            <h3 className="widget-title">Who We Are</h3>
            <div className="call-to-action-content">
              <p>
                We started our journey in the year 2014. We have worked on
                a variety of projects since then.
              </p>
              <p>
                We are a service based company. We do application development, 
                deployment, hosting, maintenance and monitoring. We also provide 
                managed services and L1/L2/L3 support.
              </p>
              <p>
                Our main focus is on delivering high quality work on time. We
                believe constant and effective communication is the key to
                setting the right expectations. As professionals, we believe in
                providing honest opinions to our clients.
              </p>
              <p>
                We have a great team of professionals having experience in a
                variety of technologies who can take on every challenge with
                confidence.
              </p>
            </div>
          </div>
        </div>
      </section>

      <OurServices />
      </div>
    </>
  );
};

export default HomePage;
