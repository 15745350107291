import Header from "./components/Header";
import "./App.scss";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import { Routes, Route, useLocation } from "react-router-dom";
import Technologies from "./pages/Technologies";
import Clients from "./pages/Clients";
import Team from "./pages/Team";
import Career from "./pages/Career";
import ScrollButton from "./components/ScrollButton";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact  path="/technologies" element={<Technologies />} />
        <Route exact  path="/clients" element={<Clients />} />
        <Route exact  path="/team" element={<Team />} />
        <Route exact  path="/career" element={<Career />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
      <ScrollButton/>
      <Footer />
    </>
  );
}

export default App;
